import React, { useEffect, useState } from "react";
import { Navbarmain } from "./Navbarmain";

import img1 from "../images/ima (2).png";
import img2 from "../images/h1-img-01.jpg.png";
import playstore from "../images/playstore.png";
import arrow from "../images/SVG.png";
import Barrow from "../images/black arrow.png";
import group from "../images/Group.png";
import star from "../images/Start.png";
import testman from "../images/testpepole.jpg";
import classroom from "../images/classsroom.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp,faAngleDown,faTimes } from '@fortawesome/free-solid-svg-icons';

import img3 from "../images/ima (1).png";
import img4 from "../images/ima (6).png";
import img5 from "../images/ima (5).png";
import img6 from "../images/ima (4).png";
import img7 from "../images/ima (3).png";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Footermain } from "./Footermain";
import { getUserdata } from "../utilities/sessionexpiry";
import { Link } from "react-router-dom";


export const Homepage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const [showContent, setShowContent] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const toggleContent = () => {
    setShowContent(!showContent);
    setShowButton(false);
  };

  const toggleButton = () => {
    setShowContent(false);
    setShowButton(true);
  };

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(true);
    const showBannerInterval = setInterval(() => {
      setShowBanner(true); 

      setTimeout(() => {
        setShowBanner(false);
      }, 4000);
    }, 8000);
  
    return () => clearInterval(showBannerInterval);
  }, []);
  


  const closeBanner = () => {
    setShowBanner(false);
  };

    const [signin, setSignin] = useState(false); // Default to false initially
  const userData = getUserdata(); //JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setSignin(true); // Data exists
    } else {
      setSignin(false); // No data
    }
  }, []); // Run this effect only once on initial render
  console.log(signin); //

  return (
    <>
    <Navbarmain />

   

      <div className="mainbox">
        <div className="minibox1">
          {/* <img
            src={img1}
            width={'100%'}
            height={"100%"}
            alt="Image"
            className="hidden md:block"
          /> */}
        </div>
        <div className="minibox2 banner-1">
          <p className="text-start">AYYA'WIN</p>
          <p className="uppercase">Study Foundation</p>
          <p className="">
            {" "}
            We're embarking on an exciting journey to empower students through a
            transformative 90-day challenge. Join us as we cultivate skills in
            leadership, resilience, and personal growth, paving the way for
            success in academia and beyond.
          </p>
          <p className="">12M + Active Students</p>
          {/* <img src={playstore} alt="" /> */}

        
           {signin ? (
        <a href="/mytask" style={{ whiteSpace: 'nowrap' }} className="flex items-center justify-center">Continue Task <img src={arrow} alt="" className="ml-3" /></a>
      ) : (

          <a href="/signup" style={{ whiteSpace: 'nowrap' }} className="flex items-center justify-center">Register Now <img src={arrow} alt="" className="ml-3" /></a>
      )}
          {showBanner && (
        <div className="popup-banner">
          <div className="banner-content">
            <button className="close-btn" onClick={closeBanner}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2>IDP Project !</h2>
    <p>Get involved in our IDP Project now!</p>
            
          </div>
        </div>
      )}
        </div>

      </div>

      <div className="" style={{ width: '100%' }}>
        <div className="block md:flex flex-wrap justify-center banner-2 ">
          <div className=" minibox3  ">
            <div className="banner-2-mini1  ">
              <h3>Student</h3>

              <p>
                Join us on a transformative journey of personal growth and
                development. Discover the path to becoming the best version of
                yourself.
              </p>
              <a href="/mytask">
                Learn more <img src={Barrow} alt="" className="ml-4" />{" "}
              </a>
            </div>
          </div>
          <div className="minibox3 ">
            <div
              className="banner-2-mini1  "
              style={{ backgroundColor: "#FFD0BA" }}
            >
              <h3>90 Days</h3>
              <p>
                Experience the thrill of personal growth while contributing to a
                sustainable future. Join us in the 90 days challenge to make a
                difference for both yourself and the planet.
              </p>
              <a href="/mytask">
                Learn more <img src={Barrow} alt="" className="ml-4 " />{" "}
              </a>
            </div>
          </div>
          <div className="minibox4">
            <div className=" ">
              <img className="boyimg w-full" src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex md:mt-20 mt-0 pt-16 aboutus container mx-auto mb-3">
        <div className="w-full md:w-2/3 p-4" id="about">
          <h2 className="text-2xl font-bold mb-4">About Us</h2>
          <p className="mb-4">
          தற்போதைய காலகட்டத்தில் ஒவ்வொருவருக்கும் தனது தனித்திறமைகளை மேம்படுத்தும் அவசியம் உள்ளது.
</p>
<p>AYYA''WIN STUDY FOUNDATION அமைப்பானது கடந்த 2014 ஆம் ஆண்டிலிருந்து பள்ளி மற்றும் கல்லூரி மாணவர்களின் திறன் மேம்பாடு, ஒருங்கிணைந்த வளர்ச்சி மற்றும் குண நலன் மேம்பாடுகள் குறித்த வகுப்புகளை நடத்தி வருகிறது. பல்வேறு எளிமையான செயல்பாடுகள் மூலமாக பன்முகத் திறமைகளை வளர்க்கும் பயிற்சிகள் மற்றும் நடவடிக்கைகளை கொடுத்து வருகிறது.</p>
          <p className="mb-4">
  It is essential for everyone to nurture their individual talents in the current era.
</p>
<p className="mb-4">
  AYYA''WIN STUDY FOUNDATION has been organizing programs since 2014 to enhance the skills, overall development, and quality improvement of school and college students. Conducting various simple activities, it offers training programs and initiatives aimed at fostering multifaceted talents.
</p>


        
          <a href="/signin" className="flex items-center ">
            Sign In <img src={arrow} alt="" className="ml-3" />
          </a>
        </div>

        <div className="w-full ">
          <div className="grid grid-rows-2 hidden md:grid grid-flow-col gap-4">
            <div className="col-span-2 ">
              <img src={img3} alt="" />
            </div>
            <div className="row-span-2 ">
              <img src={img4} alt="" />
              
            </div>
            <div className="row-span-3 ">
              <img
              // height={"900px"}
                src={img5}
                alt=""
                style={{  height: "520px" }}
              />
            </div>
            <div className="col-span-2 ">
              <img src={img6} alt="" />
            </div>
            <div className="row-span-2 col-span-2">
              <img src={img7} alt="" />
            </div>
          </div>
          <div className="col-span-2 block md:hidden ">
            <img src={img6} alt="" />
          </div>
        </div>
       
      </div>
<div className="container aboutus p-2 mx-auto">
  <h2 className="font-bold  text-xl mb-2" >IDP (CO CURRICULUM ACTIVITIES) </h2>
  <p className="text-sm  mb-4">பள்ளிகள் அறிவு சார்ந்த கல்வியை சிறப்பான முறையில் போதித்து வருகின்றனர். மாணவர்களின் தனித் திறமைகளை வளர்க்கும் பொருட்டு ஒருங்கிணைந்த செயல்பாடுகள் என்னும் திட்டத்தின் கீழ் INTEGRATED DEVELOPMENT PROGRAM (IDP) அன்றாட நிகழ்வுகளை ஒழுங்கமைத்துக் கொள்ளவும், அறிவுக்கூர்மையை வளர்த்துக் கொள்ளவும், சமூக பங்களிப்புகளை செவ்வனே செய்யவும் பயிற்சி முறை திட்டமிடப்பட்டுள்ளது. </p>
  <p className="text-md mb-4">உளவியல் ரீதியாக பல்வேறு ஆய்வுகளின் அடிப்படையில், எளிமையான செயல்பாடுகளின் மூலம் குணநலத்தில் மாற்றங்களை உருவாக்க இந்த ஒருங்கிணைந்த செயல் திட்டம் பேருதவி புரிகிறது. உளவியல் ரீதியாக 90 நாட்கள் என்பது, ஒரு செயல், நமது குணாதிசயமாக மாறுவதற்கு அடிப்படையாக இருக்கும். அதன் விளைவாக சமூகத்தில் நல்லதொரு மாற்றங்களை உருவாக்க முடியும்.</p>

  <h2 className="font-bold mb-2 text-xl" >கீழ்க்கண்ட பயிற்சிகள் இந்த செயல் திட்டத்தில் உள்ளடங்கும் </h2>
  <ul>
    <li className="text-sm mb-3">* சுய நிர்வாகம் (self discipline).</li>
    <li className="text-sm mb-3">* பண்பு நிர்வாகம் habits *ஆரோக்கியமான உணவு மற்றும் ஆரோக்கிய உணவை தயாரித்தல்.</li>
    <li className="text-sm mb-3">* வீட்டுத்தோட்டம் (உற்பத்தி மற்றும் பராமரிப்பு). </li>
    <li className="text-sm mb-3">* யோகா மற்றும் மூச்சுப் பயிற்சி. </li>
    <li className="text-sm mb-3">* நினைவாற்றல் பயிற்சிகள்.</li>
    <li className="text-sm mb-3">* விதையிலிருந்து விருட்சம் பயிற்சி.</li>
  </ul>
  





</div>
      <Swiper
        // install Swiper modules
        spaceBetween={30}
        slidesPerView={4}
        modules={[Autoplay]}
        loop={true}
        autoplay={{ delay: 4000 }}
        breakpoints={{
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 40
          }
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        className="mySwipper2 oneline "
      >
        <SwiperSlide>
          <p>Empowerment</p>
        </SwiperSlide>
        <SwiperSlide>
          <p> Education</p>
        </SwiperSlide>
        <SwiperSlide>
          <p> Environment</p>
        </SwiperSlide>
        <SwiperSlide>
          <p> Culture</p>
        </SwiperSlide>
        <SwiperSlide>
          <p> Food security</p>
        </SwiperSlide>
        <SwiperSlide>
          <p> Employment</p>
        </SwiperSlide>
      </Swiper>

      <div className="block   md:flex-row OurFeaturesmain " id="features">
        <div className=" mx-auto md:flex ">
          {/* <!-- First Column --> */}
          <div className="md:w-1/3  pr-0 flex flex-col  p-3 items-start">
            <h2 className="text-xl font-semibold mb-4">Our Features</h2>
            <p className="text-center">
              Discover the key elements that define our platform's excellence.
              From innovative solutions to unparalleled support, we're committed
              to exceeding your expectations every step of the way.
            </p>
            <a href="/signin" className="flex items-center ">
              Sign In <img src={arrow} alt="" className="ml-3" />
            </a>
          </div>

          {/* <!-- Second Column --> */}
          <div className="md:w-full p-4  flex flex-col OurFeatures2">
            {/* <!-- First Row --> */}
            <div className="md:flex flex">
              <div className="w-full md:w-1/3 p-4">
                <span>Education</span>
                <p>Education</p>
                <p>
                  Transforming lives through the power of education. Together,
                  let's break barriers and ensure access to quality education
                  for all.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr" />
              </div>

              <div className="w-full md:w-1/3 p-4">
                <span>Women Empowerment</span>
                <p>Women Empowerment</p>
                <p>
                  Empowering women to thrive in every sphere of life. Join us in
                  creating a world where every woman has the opportunity to
                  reach her full potential.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr" />
              </div>
              <div className="w-full md:w-1/3 p-4">
                <span>Environment</span>
                <p>Environment</p>
                <p>
                  Protecting our planet one step at a time.Combat climate change, and create a
                  sustainable future for generations to come.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr" />
              </div>

              <div className="w-full block md:hidden md:w-1/3 p-4">
                <span>Education</span>
                <p>Education</p>
                <p>
                  Transforming lives through the power of education. Together,
                  let's break barriers and ensure access to quality education
                  for all.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
              </div>

              <div className="w-full block md:hidden md:w-1/3 p-4">
                <span>Women Empowerment</span>
                <p>Women Empowerment</p>
                <p>
                  Empowering women to thrive in every sphere of life. Join us in
                  creating a world where every woman has the opportunity to
                  reach her full potential.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr block md:hidden" />
              </div>
              <div className="w-full block md:hidden md:w-1/3 p-4">
                <span>Environment</span>
                <p>Environment</p>
                <p>
                  Protecting our planet one step at a time. Join our initiative
                  to preserve nature, combat climate change, and create a
                  sustainable future for generations to come.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr block md:hidden" />
              </div>

            </div>

            {/* <!-- Second Row --> */}
            <div className="md:flex hidden md:flex mt-10" style={{ overflow: 'scroll' }}>
              <div className="w-full  md:w-1/3 p-4">
                <span>Education</span>
                <p>Education</p>
                <p>
                  Transforming lives through the power of education. Together,
                  let's break barriers and ensure access to quality education
                  for all.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr block md:hidden" />
              </div>

              <div className="w-full md:w-1/3 p-4">
                <span>Women Empowerment</span>
                <p>Women Empowerment</p>
                <p>
                  Empowering women to thrive in every sphere of life. Join us in
                  creating a world where every woman has the opportunity to
                  reach her full potential.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr block md:hidden" />
              </div>
              <div className="w-full md:w-1/3 p-4">
                <span>Environment</span>
                <p>Environment</p>
                <p>
                  Protecting our planet one step at a time. Join our initiative
                  to preserve nature, combat climate change, and create a
                  sustainable future for generations to come.
                </p>
                <p className="">
                  View More <img src={Barrow} alt="" className="ml-4" />{" "}
                </p>
                <hr className="linehr block md:hidden" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <Swiper
          id="reviews"
          // install Swiper modules
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwipper1"
        >
          <SwiperSlide>
            <div className="container mx-auto p-1 md:p-10">
              <div className="  flex flex-col md:flex-row p-7 md:p-16 ">
                <div className="md:w-1/2 w-full">
                  <div className="feedbox">Feedbacks</div>
                  <img src={testman} alt="" className="testimonialsimage" />
                </div>
                <div className="md:w-1/2 w-full ">
                  <img src={group} alt="" />
                  <p className="mt-4 md:mt-10">
                    I'm grateful for the opportunities the foundation has
                    offered me. It's inspiring to see how they empower students
                    to reach their full potential.
                  </p>
                  <div className="flex mt-5">
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                  </div>
                  <p className="testiname mt-5 uppercase">
                    JENIFER NISHA - <span>Student</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container mx-auto p-1 md:p-10">
              <div className="  flex flex-col md:flex-row p-7 md:p-16 ">
                <div className="md:w-1/2 w-full">
                  <div className="feedbox">Feedbacks</div>
                  <img src={testman} alt="" className="testimonialsimage" />
                </div>
                <div className="md:w-1/2 w-full ">
                  <img src={group} alt="" />
                  <p className="mt-4 md:mt-0">
                    Being part of the foundation's programs has not only expanded
                    my knowledge but also instilled in me a sense of purpose and
                    responsibility towards my community.
                  </p>
                  <div className="flex mt-5">
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                  </div>
                  <p className="testiname mt-5 uppercase">
                    JENIFER NISHA - <span>Student</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container mx-auto p-1 md:p-10">
              <div className="  flex flex-col md:flex-row p-7 md:p-16 ">
                <div className="md:w-1/2 w-full">
                  <div className="feedbox">Feedbacks</div>
                  <img src={testman} alt="" className="testimonialsimage" />
                </div>
                <div className="md:w-1/2 w-full ">
                  <img src={group} alt="" />
                  <p className="mt-4 md:mt-0">
                    The foundation's emphasis on practical learning and real-world
                    skills has equipped me with the confidence to tackle
                    challenges head-on. I highly recommend it to any student
                    seeking growth and development.
                  </p>
                  <div className="flex mt-5">
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                  </div>
                  <p className="testiname mt-5 uppercase">
                    JENIFER NISHA - <span>Student</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container mx-auto p-1 md:p-10">
              <div className="  flex flex-col md:flex-row p-7 md:p-16 ">
                <div className="md:w-1/2 w-full">
                  <div className="feedbox">Feedbacks</div>
                  <img src={testman} alt="" className="testimonialsimage" />
                </div>
                <div className="md:w-1/2 w-full ">
                  <img src={group} alt="" />
                  <p className="mt-4 md:mt-0">
                    As an aspiring leader, the mentorship provided by the
                    foundation has been invaluable. I've gained insights and
                    guidance that will undoubtedly shape my future endeavors..
                  </p>
                  <div className="flex mt-5">
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                    <img src={star} alt="" className="w-4 h-4" />
                  </div>
                  <p className="testiname mt-5 uppercase">
                    JENIFER NISHA - <span>Student</span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="ourblogs mt-16" id="blog">
        <h2>Our Blogs</h2>
        <p className="ourblogspara">
          Discuss various approaches and programs
        </p>
        <div className="flex flex-wrap container  mx-auto">
          <div className="w-full md:w-1/3 p-4">
            <div className="  overflow-hidden relative">
              <img src={classroom} alt="Placeholder" className="w-full" />
              <span className="ourblogsmsg">Empowering</span>
              <div className=" cardcontent">
                <p className="minitext py-5">
                  IDP TECH <span>February 12, 2024</span>
                </p>
                <h2 className="mt-0 md:mt-2 py-2 md:py-5 uppercase">
                  Empowering Student Leadership: Strategies for Maximizing Potential
                </h2>
                <p>
                  Discuss various approaches and programs aimed at enhancing student leadership skills, including mentorship programs, extracurricular activities, and leadership workshops.
                </p>
                <button className="my-5">Read More</button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-4">
            <div className="  overflow-hidden relative">
              <img src={classroom} alt="Placeholder" className="w-full" />
              <span className="ourblogsmsg">Education</span>
              <div className=" cardcontent">
                <p className="minitext py-5">
                  IDP TECH <span>March 11, 2024</span>
                </p>
                <h2 className="mt-0 md:mt-2 py-2 md:py-5 uppercase">
                  Promoting Environmental Awareness on Campus: Practical Tips for a Greener Future
                </h2>
                <p>
                  Offer practical advice and initiatives for universities to promote environmental sustainability, such as recycling programs, energy conservation efforts, and campus gardening projects.
                </p>
                <button className="my-5">Read More</button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 p-4">
            <div className="  overflow-hidden relative">
              <img src={classroom} alt="Placeholder" className="w-full" />
              <span className="ourblogsmsg">Partnerships</span>
              <div className=" cardcontent">
                <p className="minitext py-5">
                  IDP TECH <span>December 21, 2023</span>
                </p>
                <h2 className="mt-0 md:mt-2 py-2 md:py-5 uppercase">
                  IDP TECH Role of Technology in Student Development: Leveraging Digital Tools for Growth
                </h2>
                <p>
                  Examine how technology can be utilized to support student learning and development, including the use of educational apps, online resources, and virtual collaboration platforms.
                </p>
                <button className="my-5">Read More</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {showContent && ( */}
        <> <Footermain /> </>

      <div className="fixed bottom-4 right-4 bg-black p-4 cursor-pointer z-50" onClick={scrollToTop}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-6 h-6 text-white transform rotate-180"
        >
          <path
            fillRule="evenodd"
            d="M10 2a1 1 0 0 1 1 1v10.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L9 13.586V3a1 1 0 0 1 1-1z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </>
  );
};
