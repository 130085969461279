import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Homepage } from "./pages/Homepage";
import { Signin } from "./pages/Signin";
import { Signup } from "./pages/Signup";
import { Navbarmain } from "./pages/Navbarmain";
import { Otppage } from "./pages/Otppage";
import { Dashboard } from "./pages/Dashboard";
import { PrivacyandPolicy } from "./pages/PrivacyandPolicy";
import { Termsandcondtion } from "./pages/Termsandcondtion";
import { ShippingandRefund } from "./pages/ShippingandRefund";
import { PaymentfailedPage } from "./pages/Paymentfaield";
import { PaymentsuccessPage } from "./pages/Paymentsuccess";
import { PaidTaskPage } from "./pages/PaidTaskPage";
// import PaymentStatusChecker from "./pages/PaymentStatusChecker";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <Navbarmain /> */}  
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
          <Route path="/termsandcondtion" element={<Termsandcondtion />} />
          <Route path="/shippingandrefund" element={<ShippingandRefund />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otppage" element={<Otppage />} />
          <Route path="/mytask" element={<Dashboard />} />
          <Route path="/paymenterror" element={<PaymentfailedPage />} />
          {/* <Route path="/paymentchecker" element={<PaymentStatusChecker />} /> */}
          <Route path="/handle/payment" element={<PaymentsuccessPage />} />
          <Route path="/familytask" element={<PaidTaskPage />} />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
