import React, { useState } from "react";
import "./style.css";
import logo from "../images/logod.jpeg";
import fac from "../images/facbook.png";
import inst from "../images/instalogo.png";
import twit from "../images/twitter.png";
import link from "../images/linkedin.png";

export const Footermain = () => {


  return (
    <>
      <div className="container mx-auto flex flex-col items-start justify-center md:py-16 p-5 md:flex-row md:justify-between md:items-center">
        <img src={logo} width={'200px'} alt="" className="md:mr-4" />
        <p className="text-center md:text-left underline hidden md:block">
          © Copyrights 2024. All rights reserved
        </p>
      </div>

      <div className="flex flex-wrap justify-between container mx-auto footercontent">
        <div className="w-2/4 md:w-1/4 md:p-4 ps-0">
          <h6 className="mb-2">Education</h6>
          <a href="#" className="block mb-2">
          Empowerment
          </a>
          <a href="#" className="block mb-2">
            Sustainability
          </a>
          <a href="#" className="block mb-2">
          Innovation
          </a>
          <a href="#" className="block mb-2">
            Equality
          </a>
          <a href="#" className="block">
            Study online
          </a>
        </div>
        <div className="w-2/4 md:w-1/4 md:p-4 pt-0 pl-0">
          <h6 className="mb-2">Information</h6>
          <a
            href="/privacyandpolicy"
            target="_blank"
            className="block mb-2"
            style={{ cursor: "pointer" }}
          >
            Privacy Policy
          </a>
          <a href="/termsandcondtion" target="_blank" className="block mb-2">
            Terms And Condtions
          </a>
          <a
            href="/shippingandrefund"
            target="_blank"
            className="block mb-2"
            style={{ whiteSpace: "wrap" }}
          >
            Shipping And Refund Policy
          </a>
          {/* <a href="#" className="block">
            Take a tour
          </a> */}
        </div>
        <div className="w-2/4 md:w-1/4 p-4">
          <h6 className="mb-2">Programs</h6>
          <a href="#" className="block mb-2">
          Workshops
          </a>
          <a href="#" className="block mb-2">
           Seminars
          </a>
          <a href="#" className="block mb-2">
            Courses
          </a>
          <a href="#" className="block mb-2">
           Training
          </a>
          <a href="#" className="block">
            {" "}
            Natural Sciences
          </a>
        </div>
        <div className="w-2/4 md:w-1/4 p-4 " id="contact">
         
              <h6 className="mb-2">Contact</h6>
              <a href="#" className="block mb-2" style={{ whiteSpace: "wrap" }}>
                43, 1St floor, 3rd cross, laxmipuram, Ganapathy - 641006 .
              </a>
              <a href="mailto:nalsshoppy@gmail.com" className="block mb-2 mailtext">
                nalsshoppy@gmail.com
              </a>
              <a href="tel:+919361009480" className="block mb-2">
                +91 9361009480
              </a>
              <div className="flex justify-start space-x-6 mt-4">
                <img src={fac} alt="" />
                <img src={inst} alt="" />
                <img src={twit} alt="" />
                <img src={link} alt="" />
              </div>
          
          
        </div>
       
          
           
       
      </div>
      <div className="flex justify-center">
            <p className="py-5 pt-1   md:hidden ">
              © Copyrights 2024. All rights reserved
            </p>
            </div>
         
   
    </>
  );
};
